@charset "UTF-8";
/*base is →　! sanitize.css v5.0.0 | CC0 License | github.com/jonathantneal/sanitize.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

article, aside, footer, header, nav, section, figcaption, figure, main {
  display: block;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

img {
  border-style: none;
  vertical-align: top;
}

table {
  border-collapse: collapse;
}

button, input, select, textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button, html [type=button], [type=reset], [type=submit] {
  -webkit-appearance: button;
}

textarea {
  overflow: auto;
  resize: vertical;
}

[type=checkbox], [type=radio] {
  box-sizing: border-box;
  padding: 0;
}

a, area, button, input, label, select, summary, textarea, [tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

/* --------------- variables --------------------- */
/* Break Point */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}
.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}
.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.slick-prev:before,
.slick-next:before {
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}
[dir=rtl] .slick-prev {
  left: auto;
  right: -25px;
}
.slick-next {
  right: -25px;
}
[dir=rtl] .slick-next {
  left: -25px;
  right: auto;
}
/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}
.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}
.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}
.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

body {
  font-weight: 500;
  line-height: 1.7;
  font-family: "Noto Sans JP", sans-serif;
  background-color: #F8F8F8;
  color: #2C3A51;
}

a {
  text-decoration: none;
  color: #2C3A51;
}

a[href^="tel:"],
a[href^="fax:"] {
  text-decoration: none;
}

ul,
ol {
  list-style-type: none;
}

img {
  max-width: 100%;
  height: auto;
}

* {
  box-sizing: border-box;
}

sup,
sub {
  font-size: 0.6em;
}

:is(h1, h2, h3, h4) {
  line-height: 1;
}

#header #logo {
  transition: 0.3s;
}
#header #logo.isOpen {
  background-color: #fff !important;
}
#header #globalMenu .headerBtns a {
  background-color: #2C3A51;
  border: 1px solid #3D6D96;
  color: #fff;
}

#footer {
  background-color: #FFFFFF;
}
#footer #footerMenu .footerBtns a {
  background-color: #2C3A51;
  border: 1px solid #3D6D96;
  color: #fff;
}
#footer .copyright {
  color: #fff;
  background-color: #2C3A51;
  text-align: center;
  margin: 0;
}

.newsList a {
  display: block;
  text-decoration: none;
  color: #2C3A51;
}
/* fadeUp */
.fadeUp {
  transform: translateY(50px);
  opacity: 0;
  transition: opacity 0.8s cubic-bezier(0.32, 0.13, 0.21, 0.99), transform 0.8s cubic-bezier(0.32, 0.13, 0.21, 0.99), -webkit-transform 0.8s cubic-bezier(0.32, 0.13, 0.21, 0.99);
}
.fadeUp.active {
  opacity: 1;
  transform: translateY(0);
}

/* ココから下には記入禁止 */
@media screen and (max-width: 800px) {
  body {
    font-size: 15px;
  }
  #footer .copyright {
    font-size: 14px;
    padding: 13px;
  }
  .newsList {
    display: flex;
    flex-wrap: wrap;
    gap: 30px 20px;
  }
  .newsList li {
    width: calc(50% - 10px);
  }
  .newsList figure {
    border-radius: 9px;
    aspect-ratio: 149/86;
    overflow: hidden;
  }
  .newsList figure img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .newsList .cat {
    margin-top: 10px;
  }
  .newsList .cat span {
    display: inline-block;
    font-size: 10px;
    border-radius: 24px;
    background-color: #fff;
    padding: 7px 12px;
    line-height: 1;
  }
  .newsList p {
    font-size: 12px;
    margin-top: 10px;
  }
  /* fadeUp */
  /* ココから下には記入禁止 */
  .forPC {
    display: none !important;
  }
}
@media print, screen and (min-width: 801px) {
  body {
    font-size: 15px;
  }
  a[href^="tel:"],
  a[href^="fax:"] {
    pointer-events: none;
  }
  #footer .copyright {
    font-size: 14px;
    padding: 13px;
  }
  .newsList {
    display: flex;
    flex-wrap: wrap;
    gap: 40px 48px;
  }
  .newsList li {
    width: calc(33.3333333333% - 32px);
  }
  .newsList a:hover figure img {
    transform: scale(1.1);
  }
  .newsList figure {
    border-radius: 9px;
    aspect-ratio: 149/86;
    overflow: hidden;
  }
  .newsList figure img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: 0.5s;
  }
  .newsList .cat {
    margin-top: 15px;
  }
  .newsList .cat span {
    display: inline-block;
    font-size: 11px;
    border-radius: 24px;
    background-color: #fff;
    padding: 7px 15px;
    line-height: 1;
  }
  .newsList p {
    font-size: 14px;
    margin-top: 15px;
  }
  /* fadeUp */
  /* ココから下には記入禁止 */
  .forSP {
    display: none !important;
  }
}
@media screen and (min-width: 801px) and (max-width: 800px) {
  /* fadeUp */
  /* ココから下には記入禁止 */
}
@media screen and (max-width: 1200px) {
  #header {
    width: 100%;
    height: 76px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }
  #header #menuBtn {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: #2C3A51;
    border: 1px solid #3D6D96;
    position: absolute;
    right: 20px;
    top: 16px;
    transition: 0.3s;
    z-index: 999;
  }
  #header #menuBtn span {
    display: block;
    width: 20px;
    height: 2px;
    background-color: #fff;
    position: absolute;
    transition: 0.3s;
  }
  #header #menuBtn span:first-of-type {
    top: calc(50% - 4px);
    left: calc(50% - 10px);
  }
  #header #menuBtn span:last-of-type {
    top: calc(50% + 4px);
    left: calc(50% - 10px);
  }
  #header #menuBtn.isOpen {
    background-color: #fff;
  }
  #header #menuBtn.isOpen span {
    background-color: #2C3A51;
  }
  #header #menuBtn.isOpen span:first-of-type {
    top: calc(50% - 0px);
    transform: rotate(45deg);
  }
  #header #menuBtn.isOpen span:last-of-type {
    top: calc(50% + 0px);
    transform: rotate(-45deg);
  }
  #header #logo {
    width: 185px;
    height: 94px;
    background-color: #fff;
    border-radius: 0 0 15px 0;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    padding-left: 15px;
    z-index: 999;
  }
  #header #logo img {
    max-width: 140px;
  }
  #header #globalMenu {
    background-color: #fff;
    width: 100%;
    min-height: 100vh;
    padding: 74px 20px 24px;
    position: fixed;
    top: 0;
    left: 0;
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
    z-index: 998;
  }
  #header #globalMenu.isOpen {
    visibility: visible;
    opacity: 1;
  }
  #header #globalMenu ul a {
    color: #2C3A51;
    font-size: 16px;
    display: block;
    padding: 20px;
    position: relative;
    border-bottom: 1px solid #E8E8E8;
  }
  #header #globalMenu ul a:before {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: #E8E8E8;
    position: absolute;
    left: 0;
    top: calc(50% - 4px);
    border-radius: 50%;
  }
  #header #globalMenu ul a:after {
    content: "";
    width: 18px;
    height: 18px;
    background: url(../images/common/arrow.svg) no-repeat center/cover;
    background-color: #FED501;
    background-size: 8px;
    position: absolute;
    right: 0;
    top: calc(50% - 9px);
    border-radius: 50%;
  }
  #header #globalMenu .headerBtns {
    margin-top: 24px;
    display: flex;
    padding: 0 10px;
    gap: 22px;
  }
  #header #globalMenu .headerBtns a {
    width: calc(50% - 11px);
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    border-radius: 28px;
  }
  #footer #footerInner {
    padding: 30px 20px 60px;
  }
  #footer #footerInner #logo {
    text-align: center;
  }
  #footer #footerInner #logo img {
    max-width: 132px;
  }
  #footer #footerMenu ul {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 30px;
  }
  #footer #footerMenu ul li {
    width: calc(50% - 10px);
  }
  #footer #footerMenu ul a {
    color: #2C3A51;
    font-size: 15px;
  }
  #footer #footerMenu .footerBtns {
    margin-top: 40px;
    display: flex;
    gap: 30px;
  }
  #footer #footerMenu .footerBtns a {
    width: calc(50% - 15px);
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    border-radius: 28px;
  }
  /* fadeUp */
  /* ココから下には記入禁止 */
  .forPC_1200 {
    display: none !important;
  }
}
@media print, screen and (min-width: 1201px) {
  #header {
    height: 90px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }
  #header #menuBtn {
    display: none;
  }
  #header .headerInner {
    padding-left: 300px;
    padding-right: 30px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  #header #logo {
    width: 300px;
    height: 130px;
    background-color: #fff;
    border-radius: 0 0 27px 0;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #header #globalMenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1.5vw;
    margin-left: auto;
    height: 100%;
  }
  #header #globalMenu ul {
    display: flex;
    height: 100%;
  }
  #header #globalMenu ul li + li {
    margin-left: 1.7vw;
  }
  #header #globalMenu ul a {
    font-size: 13px;
    color: #2C3A51;
    position: relative;
    transition: 0.3s;
    height: 100%;
    display: flex;
    align-items: center;
  }
  #header #globalMenu ul a:before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: #A5C957;
    position: absolute;
    left: calc(50% - 2px);
    top: 20px;
    transition: 0.3s;
    opacity: 0;
  }
  #header #globalMenu ul a:after {
    content: "";
    display: inline-block;
    width: 50px;
    height: 2px;
    background-color: #A5C957;
    position: absolute;
    left: calc(50% - 25px);
    bottom: 15px;
    transition: 0.3s;
    opacity: 0;
  }
  #header #globalMenu ul a.current {
    color: #A5C957;
  }
  #header #globalMenu ul a.current:before {
    opacity: 1;
  }
  #header #globalMenu ul a.current:after {
    opacity: 1;
  }
  #header #globalMenu ul a:hover {
    color: #A5C957;
  }
  #header #globalMenu ul a:hover:after {
    opacity: 1;
  }
  #header #globalMenu .headerBtns {
    display: flex;
    margin-left: 1.8vw;
  }
  #header #globalMenu .headerBtns a {
    transition: 0.5s;
    width: 144px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    border-radius: 22px;
  }
  #header #globalMenu .headerBtns a + a {
    margin-left: 6px;
  }
  #header #globalMenu .headerBtns a:hover {
    color: #3D6D96;
    background-color: #fff;
  }
  #footer #footerInner {
    width: 90%;
    max-width: 1194px;
    margin: 0 auto;
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
  }
  #footer #footerMenu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 1.5vw;
    margin-left: auto;
  }
  #footer #footerMenu ul {
    display: flex;
    margin: 0 auto;
  }
  #footer #footerMenu ul li + li {
    margin-left: 2.4vw;
  }
  #footer #footerMenu ul a {
    font-size: 13px;
    color: #2C3A51;
    position: relative;
    transition: 0.3s;
  }
  #footer #footerMenu ul a:before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: #A5C957;
    position: absolute;
    left: calc(50% - 2px);
    top: -15px;
    transition: 0.3s;
    opacity: 0;
  }
  #footer #footerMenu ul a:after {
    content: "";
    display: inline-block;
    width: 50px;
    height: 2px;
    background-color: #A5C957;
    position: absolute;
    left: calc(50% - 25px);
    bottom: -15px;
    transition: 0.3s;
    opacity: 0;
  }
  #footer #footerMenu ul a:hover {
    color: #A5C957;
  }
  #footer #footerMenu ul a:hover:after {
    opacity: 1;
  }
  #footer #footerMenu .footerBtns {
    display: flex;
    margin-left: 2.5vw;
  }
  #footer #footerMenu .footerBtns a {
    transition: 0.5s;
    width: 144px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    border-radius: 22px;
  }
  #footer #footerMenu .footerBtns a + a {
    margin-left: 6px;
  }
  #footer #footerMenu .footerBtns a:hover {
    color: #3D6D96;
    background-color: #fff;
  }
  /* fadeUp */
  /* ココから下には記入禁止 */
  .forSP_1200 {
    display: none !important;
  }
}