body {
	@if $type=="" {
		font-weight: 500;
        line-height: 1.7;
        @include fontJa;
        background-color: #F8F8F8;
        color: $c_text;
	}
	@if $type ==sp {
        font-size: 15px;
	}
	@if $type ==pc {
        font-size: 15px;
	}
}

a {
	@if $type=="" {
		text-decoration: none;
		color: $c_text;
	}
}

a[href^="tel:"],
a[href^="fax:"] {
	@if $type=="" {
		text-decoration: none;
	}

	@if $type==pc {
		pointer-events: none;
	}
}

ul,
ol {
	@if $type=="" {
		list-style-type: none;
	}
}

img {
	@if $type=="" {
		max-width: 100%;
		height: auto;
	}
}

* {
	@if $type=="" {
		box-sizing: border-box;
	}
}

sup,
sub {
	@if $type=="" {
		font-size: .6em;
	}
}

:is(h1, h2, h3, h4) {
	@if $type=="" {
		line-height: 1;
	}
}

#header{
    @if $type=="" {
    }
    @if $type==sp_1200 {
        width: 100%;
        height: 76px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        #menuBtn{
            width: 44px;
            height: 44px;
            border-radius: 50%;
            background-color: #2C3A51;
            border: 1px solid #3D6D96;
            position: absolute;
            right: 20px;
            top: 16px;
            transition: .3s;
            z-index: 999;
            span{
                display: block;
                width: 20px;
                height: 2px;
                background-color: #fff;
                position: absolute;
                transition: .3s;
                &:first-of-type{
                    top: calc(50% - 4px);
                    left: calc(50% - 10px);
                }
                &:last-of-type{
                    top: calc(50% + 4px);
                    left: calc(50% - 10px);
                }
            }
            &.isOpen{
                background-color: #fff;
                span{
                    background-color: #2C3A51;
                    &:first-of-type{
                        top: calc(50% - 0px);
                        transform: rotate(45deg);
                    }
                    &:last-of-type{
                        top: calc(50% + 0px);
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
    @if $type==pc_1200 {
        height: 90px;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        #menuBtn{
            display: none;
        }
    }
    .headerInner{
        @if $type=="" {
        }
        @if $type==sp_1200 {
        }
        @if $type==pc_1200 {
            padding-left: 300px;
            padding-right: 30px;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
        }
    }
    #logo{
        @if $type=="" {
            transition: .3s;
            &.isOpen{
                background-color: #fff!important;
            }
        }
        @if $type==sp_1200 {
            width: 185px;
            height: 94px;
            background-color: #fff;
            border-radius: 0 0 15px 0;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            padding-left: 15px;
            z-index: 999;
            img{
                max-width: 140px;
            }
        }
        @if $type==pc_1200 {
            width: 300px;
            height: 130px;
            background-color: #fff;
            border-radius: 0 0 27px 0;
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    #globalMenu{
        @if $type=="" {
        }
        @if $type==sp_1200 {
            background-color: #fff;
            width: 100%;
            min-height: 100vh;
            padding: 74px 20px 24px;
            position: fixed;
            top: 0;
            left: 0;
            transition: .5s;
            opacity: 0;
            visibility: hidden;
            z-index: 998;
            &.isOpen{
                visibility: visible;
                opacity: 1;
            }
        }
        @if $type==pc_1200 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 1.5vw;
            margin-left: auto;
            height: 100%;
        }
        ul{
            @if $type=="" {
            }
            @if $type==sp_1200 {
            }
            @if $type==pc_1200 {
                display: flex;
                height: 100%;
            }
            li{
                @if $type=="" {
                }
                @if $type==sp_1200 {
                }
                @if $type==pc_1200 {
                    + li{
                        margin-left: 1.7vw;
                    }
                }
            }
            a{
                @if $type=="" {
                }
                @if $type==sp_1200 {
                    color: #2C3A51;
                    font-size: 16px;
                    display: block;
                    padding: 20px;
                    position: relative;
                    border-bottom: 1px solid #E8E8E8;
                    &:before{
                        content: "";
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                        background-color: #E8E8E8;
                        position: absolute;
                        left: 0;
                        top: calc(50% - 4px);
                        border-radius: 50%;
                    }
                    &:after{
                        content: "";
                        width: 18px;
                        height: 18px;
                        background: url(../images/common/arrow.svg) no-repeat center / cover;
                        background-color: #FED501;
                        background-size: 8px;
                        position: absolute;
                        right: 0;
                        top: calc(50% - 9px);
                        border-radius: 50%;
                    }
                }
                @if $type==pc_1200 {
                    font-size: 13px;
                    color: #2C3A51;
                    position: relative;
                    transition: .3s;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    &:before{
                        content: "";
                        display: inline-block;
                        width: 4px;
                        height: 4px;
                        background-color: $c_base;
                        position: absolute;
                        left: calc(50% - 2px);
                        top: 20px;
                        transition: .3s;
                        opacity: 0;
                    }
                    &:after{
                        content: "";
                        display: inline-block;
                        width: 50px;
                        height: 2px;
                        background-color: $c_base;
                        position: absolute;
                        left: calc(50% - 25px);
                        bottom: 15px;
                        transition: .3s;
                        opacity: 0;
                    }
                    &.current{
                        color: $c_base;
                        &:before{
                            opacity: 1;
                        }
                        &:after{
                            opacity: 1;
                        }
                    }
                    &:hover{
                        color: $c_base;
                        &:after{
                            opacity: 1;
                        }
                    }
                }
            }
        }
        .headerBtns{
            @if $type=="" {
            }
            @if $type==sp_1200 {
                margin-top: 24px;
                display: flex;
                padding: 0 10px;
                gap: 22px;
            }
            @if $type==pc_1200 {
                display: flex;
                margin-left: 1.8vw;
            }
            a{
                @if $type=="" {
                    background-color: #2C3A51;
                    border: 1px solid #3D6D96;
                    color: #fff;
                }
                @if $type==sp_1200 {
                    width: calc(50% - 11px);
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 13px;
                    border-radius: 28px;
                }
                @if $type==pc_1200 {
                    transition: .5s;
                    width: 144px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 13px;
                    border-radius: 22px;
                    + a{
                        margin-left: 6px;
                    }
                    &:hover{
                        color: #3D6D96;
                        background-color: #fff;
                    }
                }
            }
        }
    }
}//header

#footer{
    @if $type=="" {
        background-color: #FFFFFF;
    }
    @if $type==sp_1200 {
    }
    @if $type==pc_1200 {
    }
    #footerInner{
        @if $type=="" {
        }
        @if $type==sp_1200 {
            padding: 30px 20px 60px;
            #logo{
                text-align: center;
                img{
                    max-width: 132px;
                }
            }
        }
        @if $type==pc_1200 {
            width: 90%;
            max-width: 1194px;
            margin: 0 auto;
            padding: 30px 0;
            display: flex;
            justify-content: space-between;
        }
    }
    #footerMenu{
        @if $type=="" {
        }
        @if $type==sp_1200 {
        }
        @if $type==pc_1200 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 1.5vw;
            margin-left: auto;
        }
        ul{
            @if $type=="" {
            }
            @if $type==sp_1200 {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                margin-top: 30px;
            }
            @if $type==pc_1200 {
                display: flex;
                margin: 0 auto;
            }
            li{
                @if $type=="" {
                }
                @if $type==sp_1200 {
                    width: calc(50% - 10px);
                }
                @if $type==pc_1200 {
                    + li{
                        margin-left: 2.4vw;
                    }
                }
            }
            a{
                @if $type=="" {
                }
                @if $type==sp_1200 {
                    color: #2C3A51;
                    font-size: 15px;
                }
                @if $type==pc_1200 {
                    font-size: 13px;
                    color: #2C3A51;
                    position: relative;
                    transition: .3s;
                    &:before{
                        content: "";
                        display: inline-block;
                        width: 4px;
                        height: 4px;
                        background-color: $c_base;
                        position: absolute;
                        left: calc(50% - 2px);
                        top: -15px;
                        transition: .3s;
                        opacity: 0;
                    }
                    &:after{
                        content: "";
                        display: inline-block;
                        width: 50px;
                        height: 2px;
                        background-color: $c_base;
                        position: absolute;
                        left: calc(50% - 25px);
                        bottom: -15px;
                        transition: .3s;
                        opacity: 0;
                    }
                    &:hover{
                        color: $c_base;
                        &:after{
                            opacity: 1;
                        }
                    }
                }
            }
        }
        .footerBtns{
            @if $type=="" {
            }
            @if $type==sp_1200 {
                margin-top: 40px;
                display: flex;
                gap: 30px;
            }
            @if $type==pc_1200 {
                display: flex;
                margin-left: 2.5vw;
            }
            a{
                @if $type=="" {
                    background-color: #2C3A51;
                    border: 1px solid #3D6D96;
                    color: #fff;
                }
                @if $type==sp_1200 {
                    width: calc(50% - 15px);
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 13px;
                    border-radius: 28px;
                }
                @if $type==pc_1200 {
                    transition: .5s;
                    width: 144px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 13px;
                    border-radius: 22px;
                    + a{
                        margin-left: 6px;
                    }
                    &:hover{
                        color: #3D6D96;
                        background-color: #fff;
                    }
                }
            }
        }
    }
    .copyright{
        @if $type=="" {
            color: #fff;
            background-color: #2C3A51;
            text-align: center;
            margin: 0;
        }
        @if $type==sp {
            font-size: 14px;
            padding: 13px;
        }
        @if $type==pc {
            font-size: 14px;
            padding: 13px;
        }
    }
}//footer

.newsList{
    @if $type=="" {
    }
    @if $type==sp {
        display: flex;
        flex-wrap: wrap;
        gap: 30px 20px;
    }
    @if $type==pc {
        display: flex;
        flex-wrap: wrap;
        gap: 40px 48px;
    }
    li{
        @if $type=="" {
        }
        @if $type==sp {
            width: calc(50% - 10px);
        }
        @if $type==pc {
            width: calc((100% / 3 ) - 32px);
        }
    }
    a{
        @if $type=="" {
            display: block;
            text-decoration: none;
            color: #2C3A51;
        }
        @if $type==sp {
        }
        @if $type==pc {
            &:hover{
                figure img{
                    transform: scale(1.1);
                }
            }
        }
    }
    figure{
        @if $type=="" {
        }
        @if $type==sp {
            border-radius: 9px;
            aspect-ratio: 149 / 86;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        @if $type==pc {
            border-radius: 9px;
            aspect-ratio: 149 / 86;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: .5s;
            }
        }
    }
    .cat{
        @if $type=="" {
        }
        @if $type==sp {
            margin-top: 10px;
            span{
                display: inline-block;
                font-size: 10px;
                border-radius: 24px;
                background-color: #fff;
                padding: 7px 12px;
                line-height: 1;
            }
        }
        @if $type==pc {
            margin-top: 15px;
            span{
                display: inline-block;
                font-size: 11px;
                border-radius: 24px;
                background-color: #fff;
                padding: 7px 15px;
                line-height: 1;
            }
        }
    }
    p{
        @if $type=="" {
        }
        @if $type==sp {
            font-size: 12px;
            margin-top: 10px;
        }
        @if $type==pc {
            font-size: 14px;
            margin-top: 15px;
        }
    }
}

/* fadeUp */

@if $type == ""{
    .fadeUp{
        transform: translateY(50px);
        opacity:0;
        transition: opacity .8s cubic-bezier(.32,.13,.21,.99), transform .8s cubic-bezier(.32,.13,.21,.99), -webkit-transform .8s cubic-bezier(.32,.13,.21,.99);
        &.active{
            opacity: 1;
            transform: translateY(0);
        }
    }
}


.sample{
	@if $type=="" {
	}
	@if $type==sp {
	}
	@if $type==pc {
	}
}

/* ココから下には記入禁止 */

.forPC {
	@if $type==sp {
		display: none !important;
	}
}

.forSP {
	@if $type==pc {
		display: none !important;
	}
}
.forPC_1200 {
	@if $type==sp_1200 {
		display: none !important;
	}
}

.forSP_1200 {
	@if $type==pc_1200 {
		display: none !important;
	}
}